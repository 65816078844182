<template>
    <div class="container py-8 mx-auto md:py-5 lg:py-3">
        <div class="items-center justify-center px-6 sm:flex sm:flex-wrap sm:justify-between">
            <div class="w-full">
                <img src="/images/logo.png" alt=">Wei Chu Seafood Supply Trading S/B" class="mx-auto"/>
            </div>
            <!-- <div class="w-full text-center md:text-right md:w-1/3">
                <a href="tel:+60129698948" class="text-xl text-blue-600 md:text-2xl poppin-semi">
                    <span class="text-lg open-reg">Call Us At</span><br/>
                    <i class="text-red-500 fas fa-phone-alt"></i> 012-9698948
                </a>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>