<template>
  <div class="">
    <!-- Whatsapp -->
    <Whatsappbtn />

    <!-- Header -->
    <TheHeader />

    <!-- banner -->
    <TheBanner />

    <!-- Service -->
    <div
      class="py-6 bg-center bg-repeat bg-contain"
      style="background-image: url(../images/bg.jpg)"
    >
      <div class="container mx-auto">
        <h1
          class="pb-4 text-2xl italic text-center text-yellow-400 md:text-4xl poppin-semi"
        >
          Our Products
        </h1>
        <div class="flex flex-wrap justify-center open-reg">
          <div
            class="w-1/2 px-2 md:w-1/3 lg:w-1/4"
            v-for="(item, i) in proDuct"
            :key="i"
          >
            <br />
            <img :src="item.image" :alt="item.alt" class="mx-auto" />
            <p class="pt-4 pb-6 text-center text-white open-semi">
              {{ item.alt }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- About Us -->
    <div>
      <div class="mx-auto maxWidth">
        <div class="flex flex-wrap justify-center">
          <div
            class="w-full bg-center bg-no-repeat bg-cover py-36 lg:w-1/2 lg:py-0"
            style="background-image: url(../images/about-us.jpg)"
          >
            <!-- <img src="/images/about-us.jpg" alt="About Us" class="mx-auto"> -->
          </div>
          <div class="w-full px-4 py-6 lg:w-1/2">
            <h1
              class="pb-4 text-2xl italic text-center text-yellow-400 lg:text-left lg:text-4xl poppin-semi"
            >
              About Us
            </h1>
            <p class="text-sm text-gray-700 lg:text-base open-reg">
              <b>Wei Chu Seafood Supply Trading Sdn Bhd</b> is a fresh frozen
              seafood supplier company. Our main office is located in Pelabuhan
              Klang, Selangor. We supply our frozen seafood to wholesale
              distributors, caterers, restaurants, seafood eateries, and local
              customers everyday. <br /><br />
              We provide widest selection of seafood products with superior
              quality such as crab, shrimp, prawn, crayfish, snail, cod, tuna,
              salmon, squid, cuttlefish, and etc. Our products are sourced only
              from trusted, fully accredited, and halal-certified suppliers who
              share the same values of quality as we do.
              <br /><br />
              Wei Chu frozen seafood is freeze when they are fresh and maintain
              its temperature to avoid rot and bacteria. Besides that, our
              seafood products are checked by our experience fisherman before it
              sends to customers.
              <br /><br />
              If you have any questions or need more information, please contact
              us. We will be pleased to answer any enquiries you have.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Why Choose Us -->
    <div class="">
      <div class="container py-6 mx-auto text-center ">
        <h1
          class="pb-4 text-2xl italic text-center text-yellow-400 md:text-4xl poppin-semi"
        >
          Why Choose Us
        </h1>
        <div class="flex flex-wrap justify-center">
          <div
            class="w-1/2 px-3 md:w-1/4"
            v-for="(item, i) in chooseUs"
            :key="i"
          >
            <img :src="item.image" :alt="item.alt" class="mx-auto" />
            <p class="pt-4 pb-6 text-gray-800 open-sem">{{ item.alt }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- testi -->
    <div
      class="py-6 bg-center bg-repeat bg-contain"
      style="background-image: url(../images/bg.jpg)"
    >
      <div class="container py-6 mx-auto">
        <h1
          class="pb-4 text-2xl italic text-center text-yellow-400 md:text-4xl poppin-semi"
        >
          Testimonials
        </h1>
        <carousel
          :autoplay="true"
          :loop="true"
          :autoplayHoverPause="false"
          :autoplayTimeout="2500"
          :perPage="1"
        >
          <slide>
            <div class="text-center">
              <p class="italic text-white open-reg">
                - Salmon sold is fresh and great taste. The price also
                reasonable. -
              </p>
              <h1 class="pt-4 text-yellow-400 poppin-bold">Billy</h1>
            </div>
          </slide>
          <slide>
            <div class="text-center">
              <p class="text-white open-reg">
                - Delivery on time and well packed. I will definitely buy again
                from your shop. -
              </p>
              <h1 class="pt-4 text-yellow-400 poppin-bold">Syahirah</h1>
            </div>
          </slide>
          <slide>
            <div class="text-center">
              <p class="text-white open-reg">
                - Instant reply from the seller, very professional and good in
                service, answer my questions patiently, will recommend my
                friends to purchase together. -
              </p>
              <h1 class="pt-4 text-yellow-400 poppin-bold">Vimala</h1>
            </div>
          </slide>
        </carousel>
      </div>
    </div>

    <!-- Enquiry Form -->
    <div class="py-6">
      <div class="container mx-auto text-center">
        <h1
          class="pb-4 text-2xl italic text-center text-yellow-400 md:text-4xl poppin-semi"
          id="element"
        >
          Send An Enquiry
        </h1>
        <div class="px-5">
          <p class="pb-3 text-gray-700 open-reg">
            Fill out the form below and we'll get back to you as soon as
            possible
          </p>
        </div>
        <!-- feedback.activamedia.com.sg script begins here -->
        <iframe
          allowTransparency="true"
          style="min-height:520px; height:inherit; overflow:auto;"
          width="100%"
          id="contactform123"
          name="contactform123"
          marginwidth="0"
          marginheight="0"
          frameborder="0"
          src="https://feedback.activamedia.com.sg/my-contact-form-5836536.html"
        ></iframe>
        <!-- feedback.activamedia.com.sg script ends here -->
      </div>
    </div>

    <!-- Footer -->
    <div
      class="py-6 bg-center bg-repeat bg-contain"
      style="background-image: url(../images/bg.jpg)"
    >
      <div class="container mx-auto">
        <div class="justify-center lg:justify-between md:flex md:flex-wrap">
          <div
            class="w-full pb-4 text-center text-white md:w-1/2 md:text-left md:pb-0 md:pl-4"
          >
            <img
              src="/images/logo.png"
              alt=">Wei Chu Seafood Supply Trading S/B"
              class="mx-auto md:mx-0"
            />
            <p class="py-3 open-semi">
              No.1, Jalan Sungai Sama Gagah 18E/KS5, <br />
              Taman Teluk Gedung Indah, <br />
              42000 Port Klang, Selangor
            </p>
            <a href="tel:+60123668948" id="call-btn-bottom"
              ><i class="fas fa-phone-alt"></i> 012 - 3668948</a
            >
            <br />
            <a href="tel:+60125538948" id="call-btn-bottom"
              ><i class="fas fa-phone-alt"></i> 012 - 5538948</a
            >
            <br />
            <a href="tel:+60125505350" id="call-btn-bottom"
              ><i class="fas fa-phone-alt"></i> 012 - 5505350</a
            >
          </div>
          <div class="w-full px-4 mx-auto md:w-1/2 lg:w-1/3 lg:mx-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.407129239689!2d101.40429287551848!3d2.984397054142189!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdabd786b4c2f1%3A0xf601c549cff40c5c!2sWEI%20CHU%20SEAFOOD%20SUPPLY%20TRADING%20SDN%20BHD!5e0!3m2!1sen!2smy!4v1679383748330!5m2!1sen!2smy"
              width="100%"
              height="100%"
              style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <!-- copyright -->
    <Thecopyright />
  </div>
</template>

<script>
// @ is an alias to /src
import Whatsappbtn from "@/components/Whatsappbtn.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheBanner from "@/components/TheBanner.vue";
import Thecopyright from "@/components/Thecopyright.vue";

export default {
  name: "Home",
  components: {
    Whatsappbtn,
    TheHeader,
    TheBanner,
    Thecopyright,
  },
  data() {
    return {
      proDuct: [
        { image: "images/product-1.jpg", alt: "Asari Clam-Lala Jepun" },
        { image: "images/product-2.jpg", alt: "Cockles (Kerang Batu)" },
        { image: "images/product-3.jpg", alt: "Duck Meat" },
        { image: "images/product-4.jpg", alt: "Fresh Crab" },
        { image: "images/product-5.jpg", alt: "Fresh Lobster" },
        { image: "images/product-6.jpg", alt: "Fresh Prawn" },
        { image: "images/product-7.jpg", alt: "Half Shell Pink Scallop" },
        { image: "images/product-8.jpg", alt: "Octopus" },
        { image: "images/product-9.jpg", alt: "Salmon Steak" },
        { image: "images/product-10.jpg", alt: "Squid Sliced" },
        // {image: "images/product-11.jpg", alt: "Small Octopus"},
        // {image: "images/product-12.jpg", alt: "Squid Sliced"},
      ],
      chooseUs: [
        {
          image: "images/choose-1.png",
          alt: "Reasonable Price",
        },
        {
          image: "images/choose-2.png",
          alt: "Free Delivery",
        },
        {
          image: "images/choose-3.png",
          alt: "Quality Products",
        },
        {
          image: "images/choose-4.png",
          alt: "Open 365 days",
        },
      ],
    };
  },
};
</script>

<style>
/* Font */
.poppin-semi {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.poppin-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.open-reg {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.open-semi {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.open-bold {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

/* max-width */
.maxWidth {
  max-width: 1920px;
}
</style>
