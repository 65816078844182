<template>
    <div>

        <div class="py-32 bg-right bg-no-repeat bg-cover md:bg-top md:py-44" style="background-image: url(../images/banner.jpg)">
            <div class="container mx-auto">
                <div class="w-full">
                    <h1 class="text-4xl italic text-center text-white xl:text-4xl poppin-semi textShadow md:text-left "> 
                        Provide Safe and Wholesome <br> Fresh Frozen Seafood 
                    </h1>
                </div>
                <br>
                <div class="mx-auto text-xl text-center text-white md:text-left md:w-auto md:mx-0 md:text-2xl poppin-semi textShadow">
                    Call Us: <br>
                    <a href="tel:+60123668948" id="call-btn-banner1">
                        012 - 3668948
                    </a>
                    <br>
                    <a href="tel:+60125505350" id="call-btn-banner2">
                        012 - 5505350
                    </a>
                </div>
                <a v-if="!hideEnquiry" href="#" class="block w-64 py-3 mx-auto mt-10 text-lg text-center text-blue-600 bg-yellow-400 rounded-lg md:mx-0 poppin-semi animate-pulse" v-scroll-to="{el:'#element',duration: 1500,}">ENQUIRE NOW</a>
        
            </div>
        </div>

    </div>
</template>

<script>
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

export default {
    props: {
        hideEnquiry: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>
/* Shadow */
.textShadow{text-shadow: 2px 2px 2px #000;}
</style>